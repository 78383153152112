import React, { useState } from 'react';
import ThreejsModel from '../UI/ThreejsModel/ThreejsModel';
import ImageComponent from '../UI/ImageComponent/ImageComponent';
import classes from './Carousel.module.css';
import btnPrev from '../../Assests/prev.png';
import btnNext from '../../Assests/next.png';

const Carousel = ({ variantFile }) => {
  const [currentIdx, setCurrentIdx] = useState(1);
  const handlePrev = () => {
    setCurrentIdx((prevIdx) => (prevIdx - 1 + variantFile?.sequentialImages?.length)
      % variantFile?.sequentialImages?.length);
  };

  const handleNext = () => {
    setCurrentIdx((prevIdx) => (prevIdx + 1) % variantFile?.sequentialImages?.length);
  };

  return (
    <div className={classes.carouselContainer}>
      {variantFile?.isThreeModel === true ? 
      <>
        <ThreejsModel file={variantFile}/>
      </>
      :(variantFile?.sequentialImages?.length >= 0
        ?
        <>
          <button onClick={handlePrev} id={classes.btnPrev}><img src={btnPrev} alt='Prev'></img></button>
          <ImageComponent file={variantFile?.sequentialImages[currentIdx]} />
          <button onClick={handleNext} id={classes.btnNext}><img src={btnNext} alt='Next'></img></button>
        </>
        :
          <ImageComponent file={variantFile} />)
      }
    </div>
  );
};

export default Carousel;