import { useGLTF } from '@react-three/drei'
import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';

const Box = ({ material }) => {
    return (
        <mesh >
            <planeGeometry args={[10, 10]} />
            <meshStandardMaterial {...material} />
        </mesh>
    );
};

const GenerateTexture = (props) => {
    const nodes = useGLTF(props.variantItem.url)
    const materials = nodes.materials
    const { gl } = useThree();
    useEffect(() => {
        setTimeout(() => {
            const dataUrl = gl.domElement.toDataURL();
            let texturesPreview = {
              id: Date.UTC(),
              thumbnail: dataUrl,
              file: dataUrl,
              idx: 3,
            };
            props.onPreviewItemSet((item) => [...item, texturesPreview]);
            props.onSetIsTextureGenerated(true);
            props.onSetLoading(false);
          }, 1000);
    }, []);

    return (
        <>
            {Object.keys(materials).map((matName, index) => (
                <Box key={index} material={materials[matName]} />
            ))}
        </>
    );
};

export default GenerateTexture;