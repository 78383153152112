import React, { useRef, useEffect, useState } from 'react';
import classes from "../ThreejsModel/ThreejsModel.module.css";
import "@google/model-viewer/dist/model-viewer";
import Loader from '../Loader/Loader';

const ThreejsModel = ({ file }) => {
  const modelViewerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const modelViewer = modelViewerRef.current;
    console.log(modelViewer)
    const handleLoad = () => {
      setIsLoading(false);
    };

    modelViewer.addEventListener('load', handleLoad);

    return () => {
      modelViewer.removeEventListener('load', handleLoad);
    };
  }, []);
  return (
    <>
      {isLoading && <Loader loaderText={"Loading model"} />}
      <div className={classes.card}>
        <model-viewer
          ref={modelViewerRef}
          src={file?.url}
          ios-src="
          "
          poster={file}
          alt="A 3D model"
          shadow-intensity="1"
          loading="eager"
          ar
          camera-controls={{yaw:45}}
        ></model-viewer>
      </div>
    </>
  );
};

export default ThreejsModel;