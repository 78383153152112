import React, { useState } from 'react';
import ThreeSixyGenerator from '../ThreeSixyModal/ThreeSixyGenerator';
import classes from './FileDrop.module.css';
import { ThreeDModelExtensions } from '../../Constants';
import ButtonComponent from '../UI/Button/ButtonComponent';
import ChooseFile from '../../Assests/3671892_upload_iconfinder.svg'
import Help from '../Help/Help';
import btnQuestion from '../../Assests/question.png';

const ReloadAndGoHomeButton = () => {
    const handleClick = () => {
      window.location.reload();
     // window.location.href = '/';
    };
  
    return (
      <button className={classes.HomeButton} onClick={handleClick}>
        Home
      </button>
    );
  };

  const PoweredBy = () => {
      
    return (
      <div className={classes.powered}>
        <p> <a href='https://www.elevationxstudios.com/'>Powered by <b>ElevationX Studios</b></a></p>
      </div>
    );
  };

const FileDrop = () => {
    const [isThreeSixyGenerator, setIsThreeSixyGenerator] = useState(false);
    const [variantItem, setVariantItem] = useState();
    const [isHelpModelShow, setIsHelpModelShow] = useState(false);
    const HandleShowHelpModel = () => {
        setIsHelpModelShow(true);
      };
      const HandleCloseHelpModel = () => {
        setIsHelpModelShow(false);
      };
    
    const handleUpload = (e) => {
        e.preventDefault();
        if (variantItem === undefined || variantItem === null) {
            const items = e.target.files;
            if (items.length === 1) {
                const file = items[0];
                const fileExtension = file.name.split('.').pop().toLowerCase();
                const reader = new FileReader();
                const blob = new Blob([file]);
                reader.readAsArrayBuffer(blob);
                const url = URL.createObjectURL(blob);

                if (ThreeDModelExtensions.includes(fileExtension)) {
                    const newVariant = {
                        Id: Date.now(),
                        file,
                        url: url,
                        type: fileExtension,
                        textures: [],
                        sequentialImages: []
                    };
                    setVariantItem(newVariant);
                    setIsThreeSixyGenerator(true);
                }
            } else {
                window.alert("Please drop only a single file.");
            }
        }
    };

    const handleDrop = (e) => {
        console.log(e)
        e.preventDefault();
        if (variantItem === undefined || variantItem === null) {
            const items = e.dataTransfer.items;
            if (items.length === 1 && items[0].kind === 'file') {
                const item = items[0].webkitGetAsEntry();
                if (item.isFile) {
                    const file = items[0].getAsFile();
                    const fileExtension = file.name.split('.')[1];

                    const reader = new FileReader();
                    const blob = new Blob([file]);
                    reader.readAsArrayBuffer(blob);
                    const url = URL.createObjectURL(blob);

                    if (ThreeDModelExtensions.includes(fileExtension)) {
                        const newVariant = {
                            Id: Date.now(),
                            file,
                            url: url,
                            type: fileExtension,
                            textures: [],
                            sequentialImages: []
                        };
                        setVariantItem(newVariant);
                        setIsThreeSixyGenerator(true);
                    }
                } else if (item.isDirectory) {
                    window.alert("Folder is uploaded,Please select only file.");
                }
            } else {
                window.alert("Please drop only a single file.");
            }
        }
    };

    const handleAddImages = (imageArray) => {
        setVariantItem((prevVariant) => ({
            ...prevVariant,
            sequentialImages: [...prevVariant.sequentialImages, ...imageArray],
        }));
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    return (
        <>
        <div
            className={classes.fileDropContainer}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {isHelpModelShow && <Help onClose={HandleCloseHelpModel} />}
            {!isHelpModelShow && <button className={classes.btnHelp} onClick={HandleShowHelpModel} ><img src={btnQuestion} alt='Help'></img></button>}
            {isThreeSixyGenerator ? <ThreeSixyGenerator variantItem={variantItem} onAddImages={handleAddImages} />
                : <>
                    <div className={classes.btnContainer}>
                        <ButtonComponent text="Drag glTF 2.0 file or folder here" isClickable={false} />
                    </div>
                    <br></br>
                    <button className={classes.btnInput} >
                        <img src={ChooseFile} alt='file drop'></img>
                        <p>Choose file</p>
                        <input className={classes.input} type="file" accept=".glb, .gltf" name="file-input[]" onChange={handleUpload} multiple="" />
                    </button>
                </>
            }
        </div>
        <ReloadAndGoHomeButton />
        <PoweredBy />
        </>
    )
}
export default FileDrop;