import Model from "../UI/Modal/Model";
import classes from "./Help.module.css";
import btnClose from '../../Assests/close.jpeg'
import imageSpin from '../../Assests/ICON_help_spin.png'
import imagePan from '../../Assests/ICON_help_pan.png'
import imageZoom from '../../Assests/ICON_help_zoom.png'
import imageSpinMobile from '../../Assests/ICON_help_spin_mobile.png'
import imagePanMobile from '../../Assests/ICON_help_pan_mobile.png'
import imageZoomMobile from '../../Assests/ICON_help_zoom_mobile.png'
import { useMediaQuery } from 'react-responsive';

const Help = (props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Model onClose={props.onClose}>
      <div className={classes.actions}>
        <p className={classes.mouseInfoHeader}>HOW TO NAVIGATE OUR 3D VIEWER</p>
        <button className={classes.mouseInfoCLose} onClick={props.onClose}>
          <img src={btnClose} alt="Close"></img>
        </button>
      </div>
      <div className={classes.mouseinfo}>
        <div className={classes.imgtext}>
          <img className={classes.img} src={isMobile ? imageSpinMobile : imageSpin} alt="Hold left click and drag your mouse left/right to spin the model 360°"></img>
          <p className={classes.text}>
            Hold left click and drag your mouse left/right to spin the model 360°
          </p>
        </div>
        <div className={classes.imgtext}>
          <img className={classes.img} src={isMobile ? imageZoomMobile: imageZoom} alt="Scroll your mouse wheel up/down to zoom in/out"></img>
          <p className={classes.text}>
            Scroll your mouse wheel up/down to zoom in/out
          </p>
        </div>
        <div className={classes.imgtext}>
          <img className={classes.img} src={isMobile ? imagePanMobile : imagePan} alt="Hold right click and drag your mouse left/right to pan the model"></img>
          <p className={classes.text}>
            Hold right click and drag your mouse left/right to pan the model
          </p>
        </div>
      </div>
    </Model>
  )
}
export default Help;