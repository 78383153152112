import { useThree } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { useState, useRef, useEffect } from 'react'
import ThreeSixyImage from "../../Assests/ICON_ThreeSixtyview.png";
import * as THREE from 'three'

function BoundingBoxHelper({ object }) {
    const box = new THREE.Box3()
    box.setFromObject(object);
    return (
        <mesh visible={false}>
            <boxGeometry args={[box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z]} />
            <meshBasicMaterial color="blue" transparent={true} opacity={0.4} />
        </mesh>
    );
}

const Scene = (props) => {
    const nodes = useGLTF(props.variantItem.url)
    const meshRef = useRef()
    const cameraRef = useRef()

    const gl = useThree((state) => state.gl)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visible, setVisible] = useState(true);
    const [sequentialImages, setSequentialImages] = useState([]);
    const [center, setCenter] = useState(new THREE.Vector3())
    const [scale, setScale] = useState(1)

    const box = new THREE.Box3()
    box.setFromObject(nodes.scene);
    useEffect(() => {
        console.log(currentIndex * 36 * Math.PI / 180)
        if (currentIndex === 0) {
            var v1 = box.max;
            var v2 = box.min;
            var dx = v1.x - v2.x;
            var dy = v1.y - v2.y;
            var dz = v1.z - v2.z;
            var len = Math.sqrt(dx * dx + dy * dy + dz * dz);
            setScale(len)
            var boxcenter = new THREE.Vector3()
            box.getCenter(boxcenter)
            setCenter(boxcenter)
            setCurrentIndex((prevIndex) => (prevIndex + 1));
        }
        if (currentIndex > 0 && currentIndex < 11) {
            const base64Image = gl.domElement.toDataURL('image/png');
            let threeSixtyImage = {
                id: Date.UTC,
                isThreeModel: false,
                thumbnail: base64Image,
                file: base64Image,
            }
            setSequentialImages((prevItems) => [...prevItems, threeSixtyImage]);
            setCurrentIndex((prevIndex) => (prevIndex + 1));
        }
        if (currentIndex === 11) {
            setVisible(false)
            let threeSixtyPreview = {
                id: Date.UTC,
                thumbnail: ThreeSixyImage,
                file: props.variantItem.file,
                sequentialImages: sequentialImages.slice(1),
                idx: 2
            }
            props.onPreviewItemsSet((item) => [...item, threeSixtyPreview])
        }
    }, [currentIndex, gl]);
    return (
        <>
            <perspectiveCamera
                fov={45}
                ref={cameraRef}
                aspect={window.innerWidth / window.innerHeight}
                near={1}
                far={100}
                position={[0, 0, 0]}
            />
            <BoundingBoxHelper object={nodes.scene} />
            <primitive
                visible={visible}
                ref={meshRef}
                object={nodes.scene}
                scale={[2.5 / scale, 2.5 / scale, 2.5 / scale]}
                position={[0 - center.x, 0 - center.y, 0 - center.z]}
                rotation={[Math.PI / 10, (currentIndex - 1) * 36 * Math.PI / 180, 0]}
            />
        </>
    )
}
export default Scene;