import React, { useEffect, useState } from 'react';
import "../../styles.css";
import classes from './Main.module.css';
// import Preview from '../Preview/Preview';
import Carousel from '../Carousel/Carousel';

const Main = (props) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const [previewItem, setPreviewItem] = useState(props.previewItems[0]);

  const handlePrev = () => {
    setCurrentIdx((prevIdx) => (prevIdx - 1 + props.previewItems.length) % props.previewItems.length);
  };

  const handleNext = () => {
    setCurrentIdx((prevIdx) => (prevIdx + 1) % props.previewItems.length);
  };

  const handlePreviewClick = (index) => {
    setCurrentIdx(index);
  };

  useEffect(()=> {
    setPreviewItem(props.previewItems[currentIdx]);
  }, [currentIdx,props.previewItems])

  return (
    <div 
      className={classes.main} 
    >
      <div className={classes.previewMainContainer}>
        <Carousel onSetLoading={props.onSetLoading} variantFile={previewItem}/>
      </div>
     {/*} <Preview 
        onPrevClick={handlePrev} 
        onNextClick={handleNext} 
        PreviewItems={props.previewItems} 
        onHandlePreviewClick={handlePreviewClick} 
        currentIdx={currentIdx}
  /> */}
 
    </div>
  );
};

export default Main;