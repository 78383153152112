import React, {  useEffect, useState } from 'react';
import classes from "./ThreeSixyGenerator.module.css";
import ThreeDImage from "../../Assests/ICON-ThreeDview.png";
// import ButtonComponent from '../UI/Button/ButtonComponent';
import ScreenshotComponent from '../Screenshot/ScreenshotComponent';

const ThreeSixyGenerator = (props) => {
  const [isInputCompleted, setIsInputCompleted] = useState(false);
  const [screenshotComp, setScreenshotComp] = useState(false);
  const [previewItems, setPreviewItems] = useState([]);

  // eslint-disable-next-line no-lone-blocks
  {/*const handleThreeSixyGenerator = () => {
    setScreenshotComp(true);
    setIsInputCompleted(true);
  }
*/}
  useEffect(() => {
    setScreenshotComp(false);
    setIsInputCompleted(true);

    let threeDPreview = {
      id: Date.UTC,
      isThreeModel: true,
      thumbnail: ThreeDImage,
      file: props.variantItem.file,
      url: props.variantItem.url,
      idx: 1
    }
    setPreviewItems([threeDPreview]);
  }, [])
  return (
    <div className={classes.ThreeSixyScreenshotGenertor}>
      {
        isInputCompleted ?
          <ScreenshotComponent
            variantItem={props.variantItem}
            previewItems={previewItems}
            screenshotComp={screenshotComp}
            onPreviewItemSet={setPreviewItems}
            onScreenShotComp={setScreenshotComp}
          /> :
          <>
         {/*   <div id={classes.Options}>
              <p>Would you like to create an image-based 360 view?</p>
              <div onClick={handleThreeSixyGenerator}><ButtonComponent text="Create 360" /></div>
              <div onClick={handleSkip}><ButtonComponent text='Skip' /></div>
            </div>*/}
          </>
      }
    </div>
  );
};

export default ThreeSixyGenerator;