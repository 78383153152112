import { Fragment } from 'react';
import ReactDOM  from 'react-dom';
import classes from './Model.module.css'
const BackDrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onClose}></div>;
}
const Overlay = (props) => {
    return <div className={classes.modal}>
        <div className={classes.content}>{props.children}</div>
    </div>;
}
const portalElement = document.getElementById('overlay');
const Model = props => {
    return <Fragment>
        {ReactDOM.createPortal(<BackDrop onClose={props.onClose}/>,portalElement)}
        {ReactDOM.createPortal(<Overlay> {props.children}</Overlay>,portalElement)}
    </Fragment>
}
export default Model;