import classes from './Loader.module.css';
import loaderLottie from '../../../Assests/lottie_loading_pill.json';
import Lottie from 'lottie-react';
const Loader = ({loaderText}) => {
    return (
        <div className={classes.Loader}>
            <Lottie
                animationData={loaderLottie}
                loop
                autoplay
                style={{ width: 400, height: 400 }}
                />
            <p>{loaderText}</p>
        </div>
    )
}
export default Loader;