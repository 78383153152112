import { Canvas } from '@react-three/fiber'
import Main from '../Main/Main.js';
import Scene from './Scene.js';
import classes from "./ScreenshotComponent.module.css";
import { useEffect, useState } from 'react'
import GenerateTexture from '../Texture/GenerateTexture.js';
import Loader from '../UI/Loader/Loader.js';
import ThreeDImage from "../../Assests/ICON-ThreeDview.png";
import { useRef } from 'react';
import * as THREE from 'three'
import { OrbitControls } from '@react-three/drei'

export default function ScreenshotComponent(props) {
    const [loading, setLoading] = useState(true);
    const [loaderText, setLoaderText] = useState("Loading....");
    const [isTextureGenerated, setIsTextureGenerated] = useState(false);

    // const containerRef = useRef();

    // useEffect(() => {
    //     const renderer = new THREE.WebGLRenderer();
    //     renderer.setSize(window.innerWidth, window.innerHeight);
    //     renderer.shadowMap = true;
    //     renderer.current = renderer;
    //     containerRef.current.appendChild(renderer.domElement);
    //     const handleResize = () => {
    //         const width = window.innerWidth;
    //         const height = window.innerHeight;
    //         renderer.current.setSize(width, height);
    //     };
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    useEffect(() => {
        if (props.screenshotComp) {
            setLoaderText("Creating your 360 View will be ready in 5-10 seconds");
        }
        else {
            setLoaderText("Generating texture of 3d model...");
        }
        if (props.screenshotComp && props.previewItems.length === 1) {
            props.onScreenShotComp(false);
            let threeDPreview = {
                id: Date.UTC,
                isThreeModel: true,
                thumbnail: ThreeDImage,
                file: props.variantItem.file,
                url: props.variantItem.url,
                idx: 1
            }
            props.onPreviewItemSet((items) => [...items, threeDPreview]);
        }
    }, [props.screenshotComp, props.previewItems, isTextureGenerated]);
    return (
        <>
            {/* {loading && <Loader loaderText={loaderText} />} */}
            {props.screenshotComp ?
                <Canvas className={classes.ScreenShotCanvas} gl={{ preserveDrawingBuffer: true }}>
                    <Scene
                        onPreviewItemsSet={props.onPreviewItemSet}
                        glbFile={props.variantItem.file}
                        variantItem={props.variantItem} previewItems={props.previewItems}
                    />
                    <OrbitControls zoomSpeed={0.2} makeDefault dampingFactor={0.2} />
                    <ambientLight intensity={1} />
                    <directionalLight castShadow position={[2.5, 2.5, 2.5]} />
                    <spotLight intensity={1} angle={0.6} penumbra={0.5} position={[-2.5, 2.5, 2.5]} />
                </Canvas>
                : !isTextureGenerated ?
                    <div className={classes.Texture}>
                        <Canvas>
                            <GenerateTexture variantItem={props.variantItem}
                                previewItems={props.previewItems}
                                onPreviewItemSet={props.onPreviewItemSet}
                                onSetIsTextureGenerated={setIsTextureGenerated}
                                onSetLoading={setLoading}
                            />
                            <ambientLight intensity={1} />
                        </Canvas>
                    </div>
                    : <Main variantItem={props.variantItem} previewItems={props.previewItems} />
            }
        </>
    )
}